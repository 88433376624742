import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the patchnotes page.
 */
const patchnotesPageMeta: RouteMeta = {
  name: 'patchnotes',
  title: 'Patchnotes',
  path: '/patchnotes',
  description: {
    name: 'description',
    content: 'Patchnotes'
  },
  icon: 'fad fa-brackets-curly',
  showOnNav: true,
  keepalive: false,
  order: 12,
  show: true,
  requiresAuth: true,
  permissions: []
}

export default patchnotesPageMeta
